.popupContent {
  margin-top: 20vh;
}
.popupContent > div:first-child {
  height: 24px;
}
.popupContent > div:last-child {
  padding: 0 5px 10px 5px;
}
.mainText {
  padding: 5px 5px 10px 5px;
}
.buttonsContainer {
  justify-content: space-around;
  display: flex;
}
.errorContainer {
  padding: 5px;
  border: 1px solid red;
}
.primaryButton,
.secondaryButton {
  border: none;
  border-radius: 5px;
  padding: 6px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
  outline: none;
  font-family: "Roboto Geo Caps";
}
.primaryButton {
  background: #526edb;
  color: white;
}
.primaryButton:hover {
  background: #2b4dcf;
}
.primaryButton:focus {
  background: #223da5;
}
.secondaryButton {
  background: #eee;
}
.secondaryButton:hover {
  background: #d5d5d5;
}
.secondaryButton:focus {
  background: #bbbbbb;
}
.button {
  display: flex;
  align-items: center;
}
.loadingIconContainer {
  width: 12px;
  height: 12px;
  position: relative;
  margin-left: 10px;
  transform: scale(1.5);
}
.loadingIconContainer > svg {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
}
.secondaryButton .loadingIconContainer {
  filter: brightness(0);
}
