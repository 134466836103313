.input {
  font-size: 16px;
  padding: 6px 2px;
  border: none;
  outline: none;
}
.checkBox {
  border-radius: 4px;
  width: 28px;
  height: 28px;
  background-color: #ced2fa;
  margin: 5px;
  cursor: pointer;
}
.checkBox:active,
.checkBox.isChecked {
  background-image: url("img/tick.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
}
.openBox {
  width: 13px;
  height: 13px;
  background-size: contain;
  margin: 5px;
  cursor: pointer;
}
.parentsName {
  margin: 5px;
  display: inline-flex;
  flex: 1;
}
.multipleSelectContainer {
  position: relative;
  max-width: 100%;
}
.multipleSelectContainer .allContent {
  display: flex;
  border-radius: 8px;
  position: absolute;
  z-index: 1000;
  background: white;
  width: 100%;
  max-width: 100%;
  text-align: left;
  margin-top: 10px;
  padding: 10px;
  max-height: 70vh;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
}
.multipleSelectSearchBar {
  display: flex;
  border-radius: 7px;
  border: 1px solid #9a9494;
}
.topParent {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-radius: 10px;
  border-bottom: solid #e5e5e5 1.5px;
}
.parentAndBoxes {
  display: inline-flex;
  align-items: center;
  padding: 2px 12px;
  margin: 1px;
  outline: none;
}
.searchItems {
  border-radius: 8px;
  padding: 2px 4px;
  background-color: white;
  cursor: text;
  flex: 1;
  border-radius: 8px 0px 0px 8px;
}
.searchIcon {
  padding: 0 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: white;
  border-radius: 0 8px 8px 0;
  border-left: 1px solid #ccc;
  color: #585858;
}
.checkedItems {
  display: inline-flex;
  vertical-align: middle;
  background-color: #ced2fa;
  color: black;
  margin: 3px;
  position: relative;
  font-size: 16px;
  border-radius: 5px;
  align-items: center;
}
.deleteButton {
  cursor: pointer;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: 0.2s;
  align-self: stretch;
}
.deleteButton:hover {
  background-color: #ff6b6b;
  color: white;
}
