.itemWithAppealsContainer {
  display: block;
  margin: 40px 0;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}
.itemheader {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e3e3e3;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 10px;
}
.itemheader > span {
  flex: 1;
}
.itemheader > svg {
  cursor: pointer;
  color: #d2d2d2;
}
.itemheader:hover > svg {
  color: #0077dd;
}
.itemheader:hover > svg.deleteIcon {
  color: #e93737;
}
.appeals {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.appeals .appeal:last-of-type {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.appeal {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
}
.appeal:hover {
  background: rgba(0, 0, 0, 0.05);
}
.appeal > span {
  flex: 1;
}
.appeal > svg {
  cursor: pointer;
  color: #d2d2d2;
}
.appeal:hover > svg.deleteIcon {
  color: #e93737;
}
