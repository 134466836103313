@font-face {
    font-family: "RobotoUpperCase";
    src: url('../../styles/fonts/roboto_geo_caps.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "RobotoLowerCase";
    src: url('../../styles/fonts/roboto_geo_nus.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

$grid-column-width: 240px;
.grid {
    max-width: 100%;
    display: inline-grid;
    grid-auto-flow: dense;
    grid-template-columns: $grid-column-width $grid-column-width $grid-column-width $grid-column-width;
    grid-gap: 20px;
    @media screen and (max-width: 1100px) {
        grid-template-columns: $grid-column-width $grid-column-width $grid-column-width;
    }
    @media screen and (max-width: 860px) {
        grid-template-columns: $grid-column-width $grid-column-width;
    }
    @media screen and (max-width: 560px) {
        grid-template-columns: $grid-column-width;
    }
}

.item {
    background: #142a49;
    color: white;
    padding: 15px 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.title {
    background-color: #e63946;
    font-family: "BPGNinoMtavruliBOLD";
    padding: 8px;
    text-align: center;
    border-radius: 4px;
    font-size: 21px;
}

.description {
    background-color: #11243e;
    font-family: "FiraGO";
    padding: 8px;
    text-align: center;
    border-radius: 4px;
    font-size: 21px;
    flex: 1;
}

.photoBox {
    margin: 45px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.photo {
    width: 150px;
    height: 150px;
    border-radius: 200px;
    background-size: cover;
    background-color: #36C99D;
    position: relative;
    img {
        position: absolute;
        top: 19%;
        left: 15%;
        width: 85%;
    }
}

.photo.locked {
    filter: grayscale(1) opacity(0.9);
}

.photo.current {
    background-color: #FFB951;
}