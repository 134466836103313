.itemContainer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px;
    border-radius: 10px;
    min-width: 40px;
    height: 40px;
    padding: 5px;
    margin: 8px;
    font-size: 17px;
    font-family: "Roboto";
    font-weight: bold;
}

.itemContainer.isAccessible {
    cursor: pointer;
    border-color: #5273e6;
    color: #5273e6;
}

.itemContainer.isAccessible:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
}

.itemContainer.hasAnswered {
    background: rgba(92, 93, 232, 0.2);
}

.itemContainer.hasAnsweredFully {
    background: #5273e6;
    color: white;
}

.itemContainer.isSelected {
    transform: scale(1.2);
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
}

.itemContainer.hasAnsweredCorreclty {
    border-color: #37d7a0;
    background: #37d7a0;
    color: white;
}

.itemContainer.hasAnsweredPartiallyCorreclty {
    border-color: #f6d758;
    background: #f6d758;
    color: white;
}

.itemContainer.hasAnsweredIncorreclty {
    border-color: #ef6b85;
    background: #ef6b85;
    color: white;
}

.itemContainer.hasAnswered.isNotAccessible {
    border-color: #5f7a85;
    background: #5f7a85;
    color: white;
}

.itemContainer.containsGradableItemsByEditor.hasAnswered {
    border-color: #b5c017;
    background: #b5c017;
    color: white;
}

.itemContainer.finishItem.isAccessible {
    background: #5273e6;
    color: white;
}

.itemContainer.finishItem.isNotAccessible {
    color: #ccc;
}

.finishItem>*,
.startItem>* {
    display: inline-flex;
}

.touTitle {
    text-align: center;
    font-family: "Roboto Geo MT Bold";
    margin-left: auto;
    margin-right: auto;
    color: #4e4e4e;
    font-size: 8px;
    position: absolute;
    margin-top: 33px;
    width: 220px;
}