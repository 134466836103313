.searchContainer {
  display: flex;
}
.searchContainer > div:last-of-type {
  flex: 1;
}
@media screen and (max-width: 800px) {
  .searchContainer {
    display: block;
  }
}
.pointer {
  cursor: pointer;
}
.label {
  padding: 2px 4px;
  vertical-align: middle;
  border-radius: 2px;
  margin: 3px;
}
.folderLabel {
  padding: 2px 4px;
  vertical-align: middle;
  border-radius: 2px;
  margin: 3px;
  background: rgba(254, 215, 101, 0.15);
}
.topicLabel {
  padding: 2px 4px;
  vertical-align: middle;
  border-radius: 2px;
  margin: 3px;
  background: rgba(87, 200, 40, 0.15);
}
.taskTypeLabel {
  padding: 2px 4px;
  vertical-align: middle;
  border-radius: 2px;
  margin: 3px;
  background: rgba(146, 89, 219, 0.15);
}
