@media screen and (min-height: 500px) {
  .smallContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1200px;
    max-width: 95%;
  }
}
.largeTextContainer {
  width: 1200px;
  max-width: 95%;
  margin: 10px auto;
}
.euroUniHeader {
  font-family: "BPGNinoMtavruli";
  padding: 20px 70px;
  background: linear-gradient(136deg, #81ba52, #6c9c45 50%, #81ba52 50%);
  border-radius: 18px 18px 0 0;
  text-align: center;
  color: white;
  font-size: 20px;
}
.euMainButton {
  font-family: "BPGNinoMtavruli";
  border: none;
  background: #6f9dc7;
  border-radius: 15px;
  padding: 15px 30px;
  font-size: 20px;
  color: white;
  margin: 20px auto;
  cursor: pointer;
  outline: none;
}
